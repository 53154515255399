<template>
  <BasePage :loading="loading">
    <Container v-if="!loading">
      <section>
        <div
          class="text-center d-flex flex-column justify-center align-center mb-8"
        >
          <h4 class="text-h4 mb-8 primary--text ">
            {{ $t('coachingPageTitle') }}
          </h4>
        </div>

        <v-row class="mt-5" v-if="sessions && !loading">
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            v-for="session in sessions"
            :key="session.id"
          >
            <ProductItem :product="session" :coaching="true" />
          </v-col>
          <!-- <v-col cols="4">{{ $t('coachingPageTitle') }}</v-col> -->
        </v-row>
      </section>
    </Container>
  </BasePage>
</template>

<script>
import ApiService from '@/services/api.service';
export default {
  components: {
    ProductItem: () => import('../../components/ProductItem'),
  },
  data() {
    return {
      loading: false,
      sessions: null,
    };
  },
  methods: {
    async getSessions() {
      try {
        this.loading = true;
        const { data } = await ApiService.get(
          'items/sessions?fields=*,currency_id.title,image.data.full_url'
        );
        this.sessions = data.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getSessions();
  },
};
</script>

<style scoped></style>
